import React, { useRef, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {
  Button,
  Chip,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Skeleton,
} from "@mui/material";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../../css/Home.css";
import AWS from "aws-sdk";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as XLSX from "xlsx";
import GraphicEqRoundedIcon from "@mui/icons-material/GraphicEqRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import excelIcon from "../../assets/icons8-excel.svg";
import NavBar from "../Navbar/Navbar";

export default function ResumeInsight() {
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const location = useLocation();
  const JobTitle = location.state && location.state.jobTitle;
  const tech_call = location.state && location.state.technicalCall;
  const coding_assessment = location.state && location.state.codingAssessment;
  const aptitude_assessment =
    location.state && location.state.aptitudeAssessment;
  const pause_status = location.state && location.state.pauseStatus;
  const scr_status = location.state && location.state.screeningStatus;
  const job_disable = location.state && location.state.jobDisable;
  const [overallScore, setOverallScore] = useState(0);
  console.log("aptitude check", aptitude_assessment);
  console.log("...title", JobTitle);
  const token = localStorage.getItem("idToken");
  const [initialRows, setInitialRows] = React.useState([]);
  const [fileName, setfileName] = useState("");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const DrawerHeader = styled("div")(({ theme }) => ({
    // display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  }));
  const [resumeData, setResumeData] = useState("");

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setOpen(false); // Close the drawer on smaller screens
      } else {
        setOpen(true); // Keep the drawer open on larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize on component mount
    handleResize();

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  console.log("fileName...", fileName);

  // Function to handle API call and assign unique ids to rows
  const fetchResumeData = useCallback(async () => {
    try {
      const baseUrl = process.env.REACT_APP_COUCAL_API_BASE_URL;
      const response = await axios.post(
        `${baseUrl}/resumeinsights`,
        {
          resume_id: location.state.resume_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response:", response.data);
      const data = response.data.resume_files;
      setResumeData(data);
      const candidateScore = parseFloat(data.candidate_score) || 0;
      const candidateTechnicalScore =
        parseFloat(data.Candidate_Technical_Score) || 0;
      const totalRanking = parseFloat(data.totalranking) || 0;
      const optimizationScore = parseFloat(data.OptimizationScore) || 0;
      const countScore = data.total_count_modules || 1;

      const scores = [
        candidateScore,
        candidateTechnicalScore,
        totalRanking,
        optimizationScore,
      ];
      const availableScores = scores.filter((score) => score !== 0);
      const scoreCount = availableScores.length;
      console.log("count", countScore);
      const totalScore = availableScores.reduce((acc, score) => acc + score, 0);
      const overall = scoreCount > 0 ? totalScore / countScore : 0;
      console.log("Total Score:", totalScore / countScore);
      console.log("Overall Score:", overall.toFixed(3));
      setOverallScore(overall);

      setLoading(false);
    } catch (error) {
      console.error("Error while making API call:", error);
      setLoading(false);
    }
  }, [location.state.resume_id, token]);
  useEffect(() => {
    fetchResumeData(); // Initial fetch

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(() => {
      fetchResumeData();
    }, 1 * 60 * 1000); // 5 minutes in milliseconds

    // Clean up function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [fetchResumeData]);

  const handleBack = () => {
    const { job_id } = resumeData;
    navigate("/report", {
      state: {
        job_id,
        tech_call,
        JobTitle,
        coding_assessment,
        aptitude_assessment,
        pause_status,
        job_disable,
        scr_status,
      },
    });
    console.log("resume", resumeData);
  };
  const [profilePictureAttribute, setProfilePictureAttribute] = useState(null);
  const [emailAttribute, setEmailAttribute] = useState(null);
  const accessToken = localStorage.getItem("accessToken");
  const [nameAttribute, setNameAttribute] = useState(null);
  const [LastnameAttribute, setLastNameAttribute] = useState(null);

  useEffect(() => {
    const getUserAttributes = async () => {
      const cognitoIdentityServiceProvider =
        new AWS.CognitoIdentityServiceProvider();

      try {
        // Get the authenticated user
        const user = await cognitoIdentityServiceProvider
          .getUser({ AccessToken: accessToken })
          .promise();
        console.log("..user..", user);
        const attributes = user.UserAttributes;
        const emailAttribute = attributes.find((attr) => attr.Name === "email");
        const profilePictureAttribute = attributes.find(
          (attr) => attr.Name === "picture"
        );
        const nameAttribute = attributes.find(
          (attr) => attr.Name === "given_name"
        );
        const LastNameAttribute = attributes.find(
          (attr) => attr.Name == "family_name"
        );
        setProfilePictureAttribute(profilePictureAttribute?.Value);

        setEmailAttribute(emailAttribute?.Value);
        setNameAttribute(nameAttribute?.Value);
        setLastNameAttribute(LastNameAttribute?.Value);
      } catch (error) {
        if (
          error.code === "NotAuthorizedException" &&
          error.message === "Invalid Access Token"
        ) {
          // If the token is expired, attempt to refresh it
          // Implement your token refresh logic here
          console.error("Access token expired. Implement token refresh logic.");
        } else {
          console.error("Error getting user attributes:", error);
        }
      }
    };
    getUserAttributes();
  }, []);

  useEffect(() => {
    const checkAuthentication = () => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (!accessToken || !isAccessTokenValid(accessToken)) {
        handleInvalidAccessToken();
      } else {
        setIsLoggedIn(true);
        checkScreenActivity();
      }
    };

    const isAccessTokenValid = (accessToken) => {
      try {
        const decodedToken = new CognitoAccessToken({
          AccessToken: accessToken,
        });
        const expirationTime = decodedToken.getExpiration();
        const currentTime = Math.floor(Date.now() / 1000);
        return expirationTime > currentTime;
      } catch (error) {
        console.error(
          "Error decoding token or missing expiration time:",
          error
        );
        return false;
      }
    };

    const handleInvalidAccessToken = () => {
      localStorage.setItem("loggedIn", false);
      navigate("/");
    };

    const checkScreenActivity = () => {
      let isActive = false;

      const handleMove = () => {
        isActive = true;
      };

      window.addEventListener("mousemove", handleMove);

      setTimeout(() => {
        window.removeEventListener("mousemove", handleMove);
        if (isActive) {
          refreshAccessToken();
        }
      }, 3000);
    };

    const refreshAccessToken = () => {
      const region = process.env.REACT_APP_AWS_REGION;
      const apiUrl = `https://cognito-idp.${region}.amazonaws.com/`;
      const requestBody = {
        AuthFlow: "REFRESH_TOKEN",
        ClientId: process.env.REACT_APP_CLIENT_ID_USER,
        AuthParameters: {
          REFRESH_TOKEN: localStorage.getItem("refreshToken"),
        },
      };
      const headers = {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      };

      axios
        .post(apiUrl, requestBody, { headers })
        .then((response) => {
          // Handle the response as needed
        })
        .catch((error) => {
          // Handle errors
          console.error("Error in Axios refresh POST request:", error);
        });
    };

    checkAuthentication();

    const accessTokenTimeout = setTimeout(checkAuthentication, 2 * 60 * 1000);
    const sessionTimeout = setTimeout(() => {
      // toast.error("Session expired");
      handleInvalidAccessToken();
    }, 60 * 60 * 1000);

    return () => {
      clearTimeout(accessTokenTimeout);
      clearTimeout(sessionTimeout);
    };
  }, []);

  if (!isLoggedIn) {
    return null;
  }

  const handleDownloadExcel = () => {
    const sections = [
      {
        title: "Candidate Details",
        fields: [
          "Name",
          "Current_Location",
          "Profile_Link",
          "Work_Experience",
          "Qualification",
          "Projects",
          "Email_ID",
          "Phone_Number",
          "Skills",
          "candidate_score",
        ],
      },
      {
        title: "Screening Call Details",
        fields: [
          "screening_call_status",
          "Candidate_Interested",
          "Can_work_in_diff_shift",
          "Candidate_Current_Hourly_Price",
          "Candidate_Expencted_Hourly_Price",
          "Candidate_Expected_Salary",
          "Candidate_Current_Salary",
          "Candidate_Relocation_Consent",
          "Candidate_Notice_Period",
          "Candidate_Location",
          "totalranking",
        ],
      },
      {
        title: "Technical Call Details",
        fields: [
          "tech_call_status",
          "Candidate_Interested_For_Tech_Call",
          "Candidate_Strength",
          "Candidate_Weakness",
          "Candidate_Technical_Score",
          "Description",
        ],
      },
      {
        title: "Coding Details",
        fields: [
          "OptimizationScore",
          "coding_round_status",
          "Optimization_Explanation",
        ],
      },
      {
        title: "Aptitude Details",
        fields: ["overall_aptitude_score", "aptitude_round_status"],
      },
    ];

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Function to safely get value and convert arrays to strings
    const safeGetValue = (obj, key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        return value.join(", ");
      }
      return value !== undefined && value !== null ? String(value) : "";
    };

    sections.forEach((section) => {
      // Create data for the current sheet
      const sheetData = [
        section.fields, // Header row
        section.fields.map((field) => safeGetValue(resumeData, field)), // Data row
      ];

      // Create a worksheet for the current section
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

      // Set column widths
      const columnWidths = section.fields.map(() => ({ wch: 20 })); // Default width of 20 for all columns
      worksheet["!cols"] = columnWidths;

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, section.title);
    });

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "resume_data.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F9F9F9",
        // width: "auto",
        // height: "auto",
        paddingLeft: 6,
      }}
    >
      <CssBaseline />
      <NavBar
        pageTitle="Resume Insights"
        showNameWithTitle={false}
        nameAttribute={nameAttribute}
        LastnameAttribute={LastnameAttribute}
        profilePictureAttribute={profilePictureAttribute}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        open={open} // Pass the open state
        setOpen={setOpen} // Pass the setOpen function
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pl: open ? 20 : 5,
          pr: 3,
          pb: 5,
          backgroundColor: isDarkMode ? "black" : "white",
        }}
      >
        <DrawerHeader />
        {loading ? (
          <Box sx={{ padding: 3 }}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={60}
              sx={{ marginBottom: 2 }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={400}
              sx={{ marginBottom: 2 }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={400}
              sx={{ marginBottom: 2 }}
            />
            <Skeleton variant="rectangular" width="100%" height={400} />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 3,
                // mb: 2,
                padding: 2,
                // backgroundColor: '#f5f5f5',
                // borderRadius: 2,
                // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              <Button
                onClick={handleBack}
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                sx={{
                  borderRadius: 20,
                  borderColor: "#3f51b5",
                  color: "#3f51b5",
                  "&:hover": {
                    backgroundColor: "#e8eaf6",
                  },
                }}
              >
                Back
              </Button>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#ffffff",
                  borderRadius: 16,
                  padding: "8px 16px",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#36454F", mr: 1 }}
                >
                  Total Score:
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#4caf50",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {overallScore.toFixed(2)}
                  {/* <TrendingUpIcon sx={{ ml: 0.5, fontSize: '0.8em', color: '#4caf50' }} /> */}
                </Typography>
              </Box>

              <Button
                variant="outlined"
                endIcon={
                  <img
                    src={excelIcon}
                    alt="Excel"
                    style={{ width: 20, height: 20 }}
                  />
                }
                onClick={handleDownloadExcel}
                sx={{
                  borderRadius: 50, // Fully rounded corners
                  // backgroundColor: "#2E8B57", // Excel green color
                  color: "#2E8B57",
                  borderColor: "#2E8B57",
                  padding: "6px 16px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  textTransform: "none",
                  fontWeight: 600,
                  // boxShadow: 'none',
                  "&:hover": {
                    backgroundColor: "#1e6f3e", // Slightly darker green on hover
                    boxShadow: "none",
                    color: "whitesmoke",
                  },
                }}
              >
                Download
              </Button>
            </Box>{" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 4,
                gap: 4,
              }}
            >
              {[
                "Candidate Details",
                resumeData.screening_call_check === "1"
                  ? "Screening Call Details"
                  : null,
                resumeData.technical_call_check === "1"
                  ? "Technical Call Details"
                  : null,
                resumeData.coding_assessment_check === "1"
                  ? "Coding Assessment Details"
                  : null,
                resumeData.aptitude_assessment_check === "1"
                  ? "Aptitude Assessment Details"
                  : null,
              ]
                .filter(Boolean)
                .map((title, index) => (
                  <Card
                    key={index}
                    sx={{
                      maxWidth: "100%",
                      width: "100%",
                      borderRadius: 8,
                      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                      mb: 4,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      backgroundColor: isDarkMode ? "#333" : "#f9f9f9",
                      transition: "transform 0.3s",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          fontWeight: "bold",
                          color: "primary.main",
                          textAlign: "center",
                          mb: 2,
                        }}
                      >
                        {title}
                      </Typography>
                      <TableContainer
                        component={Paper}
                        variant="outlined"
                        sx={{
                          borderRadius: 4,
                          overflow: "hidden",
                        }}
                      >
                        <Table>
                          <TableBody>
                            {index === 0 && (
                              <>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      width: "200px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Name
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ padding: "12px 24px" }}>
                                    <Typography variant="body1">
                                      {resumeData.Name}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      width: "200px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Email ID
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ padding: "12px 24px" }}>
                                    <Typography variant="body1">
                                      {Array.isArray(resumeData.Email_ID)
                                        ? resumeData.Email_ID.join(", ")
                                        : resumeData.Email_ID}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      width: "200px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Phone Number
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ padding: "12px 24px" }}>
                                    <Typography variant="body1">
                                      {Array.isArray(resumeData.Phone_Number)
                                        ? resumeData.Phone_Number.join(", ")
                                        : resumeData.Phone_Number}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      width: "200px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Profile Link
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ padding: "12px 24px" }}>
                                    <Typography variant="body1">
                                      {resumeData.Profile_Link}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Work Experience
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Box display="flex" flexWrap="wrap">
                                      <Typography variant="body1">
                                        {Array.isArray(
                                          resumeData.Work_Experience
                                        )
                                          ? resumeData.Work_Experience.join(
                                              ", "
                                            )
                                          : resumeData.Work_Experience}{" "}
                                        years
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Current Location
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Box display="flex" flexWrap="wrap">
                                      <Typography variant="body1">
                                        {Array.isArray(
                                          resumeData.Current_Location
                                        )
                                          ? resumeData.Current_Location.join(
                                              ", "
                                            )
                                          : resumeData.Current_Location}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Qualification
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Box display="flex" flexWrap="wrap">
                                      <Typography variant="body1">
                                        {Array.isArray(resumeData.Qualification)
                                          ? resumeData.Qualification.join(", ")
                                          : resumeData.Qualification}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Projects
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Box display="flex" flexWrap="wrap">
                                      <Typography variant="body1">
                                        {Array.isArray(resumeData.Projects)
                                          ? resumeData.Projects.join(", ")
                                          : resumeData.Projects}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Skills
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Box display="flex" flexWrap="wrap">
                                      {resumeData.Skills.map((skill, index) => (
                                        <Chip
                                          key={index}
                                          label={skill}
                                          sx={{
                                            marginRight: 1,
                                            marginBottom: 1,
                                          }}
                                          color="primary"
                                          variant="outlined"
                                        />
                                      ))}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    "&:nth-of-type(even)": {
                                      backgroundColor: "#f0f0f0",
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "12px 24px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#36454F",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Resume Score
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{ padding: "12px 24px" }}>
                                    <Typography variant="body1">
                                      {resumeData.candidate_score !== null &&
                                      resumeData.candidate_score !== undefined
                                        ? resumeData.candidate_score
                                        : "Not Available"}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {resumeData.resume_download_url &&
                                  resumeData.resume_download_url !==
                                    "Resume Not Available." && (
                                    <TableRow
                                      sx={{
                                        "&:nth-of-type(even)": {
                                          backgroundColor: "#f0f0f0",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          padding: "12px 24px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: "#36454F",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Candidate Resume
                                        </Typography>
                                      </TableCell>
                                      <TableCell sx={{ padding: "12px 24px" }}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          startIcon={<ArticleRoundedIcon />}
                                          onClick={() => {
                                            if (
                                              resumeData.resume_download_url
                                            ) {
                                              window.open(
                                                resumeData.resume_download_url,
                                                "_blank"
                                              );
                                            }
                                          }}
                                          sx={{
                                            borderRadius: 20,
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            boxShadow:
                                              "0 2px 5px rgba(0,0,0,0.2)",
                                            "&:hover": {
                                              boxShadow:
                                                "0 4px 8px rgba(0,0,0,0.3)",
                                            },
                                          }}
                                        >
                                          Download
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  )}
                              </>
                            )}
                            {index === 1 &&
                              resumeData.screening_call_check === "1" && (
                                <>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Screening Call Status
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.screening_call_status ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Candidate Interested
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Interested_For_screening_Call ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Work in different shifts
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Can_work_in_diff_shift ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                      display:
                                        resumeData.Candidate_Current_Salary
                                          ? "table-row"
                                          : "none",
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Current Monthly Salary
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Current_Salary ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                      display:
                                        resumeData.Candidate_Expected_Salary
                                          ? "table-row"
                                          : "none",
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Expected Monthly Salary
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Expected_Salary ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                      display:
                                        resumeData.Candidate_Current_Hourly_Price
                                          ? "table-row"
                                          : "none",
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Current Hourly Price
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Current_Hourly_Price ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                      display:
                                        resumeData.Candidate_Expencted_Hourly_Price
                                          ? "table-row"
                                          : "none",
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Expected Hourly Price
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Expencted_Hourly_Price ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Candidate location
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Location ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Notice Period
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Notice_Period ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Candidate Relocation
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Relocation_Consent ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Screening Call Score
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.totalranking ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  {resumeData.Additional_Ques &&
                                    resumeData.Additional_Ques.map(
                                      (item, index) => {
                                        const question = Object.keys(item)[0];
                                        // Handle different answer formats
                                        const rawAnswer = item[question];
                                        const answer =
                                          typeof rawAnswer === "object" &&
                                          Object.keys(rawAnswer).length === 1
                                            ? Object.values(rawAnswer)[0]
                                            : rawAnswer;

                                        return (
                                          <TableRow
                                            key={index}
                                            sx={{
                                              "&:nth-of-type(even)": {
                                                backgroundColor: "#f0f0f0",
                                              },
                                            }}
                                          >
                                            <TableCell
                                              sx={{
                                                padding: "12px 24px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  color: "#36454F",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {question}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              sx={{ padding: "12px 24px" }}
                                            >
                                              <Typography variant="body1">
                                                {answer || "Not available"}
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  {resumeData.initial_screening_recording_download_url !==
                                    "Initial screening recording not available." && (
                                    <TableRow
                                      sx={{
                                        "&:nth-of-type(even)": {
                                          backgroundColor: "#f0f0f0",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          padding: "12px 24px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: "#36454F",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Screening Call Recording
                                        </Typography>
                                      </TableCell>
                                      <TableCell sx={{ padding: "12px 24px" }}>
                                        {resumeData.initial_screening_recording_download_url !==
                                        "Initial screening recording not available." ? (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<GraphicEqRoundedIcon />}
                                            onClick={() => {
                                              if (
                                                resumeData.initial_screening_recording_download_url
                                              ) {
                                                window.open(
                                                  resumeData.initial_screening_recording_download_url,
                                                  "_blank"
                                                );
                                              }
                                            }}
                                            sx={{
                                              borderRadius: 20,
                                              textTransform: "none",
                                              fontWeight: "bold",
                                              boxShadow:
                                                "0 2px 5px rgba(0,0,0,0.2)",
                                              "&:hover": {
                                                boxShadow:
                                                  "0 4px 8px rgba(0,0,0,0.3)",
                                              },
                                            }}
                                          >
                                            Download
                                          </Button>
                                        ) : (
                                          <Typography variant="body1">
                                            Not available
                                          </Typography>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              )}
                            {index ===
                              (resumeData.screening_call_check === "0"
                                ? // resumeData.technical_call_check === "0"
                                  1
                                : 2) &&
                              resumeData.technical_call_check === "1" && (
                                <>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Technical Call Status
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.tech_call_status ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Candidate Interested
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Interested_For_Tech_Call ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Strength
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Strength ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Weakness
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Weakness ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Feedback
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Description ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        English Proficiency Score
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.English_Proficiency_Score ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Candidate Technical Score
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Candidate_Technical_Score ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  {resumeData.tech_screening_recording_download_url &&
                                    resumeData.tech_screening_recording_download_url !==
                                      "Technical Screening Recording Not Available." && (
                                      <TableRow
                                        sx={{
                                          "&:nth-of-type(even)": {
                                            backgroundColor: "#f0f0f0",
                                          },
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            padding: "12px 24px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#36454F",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Technical Call Recording
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          sx={{ padding: "12px 24px" }}
                                        >
                                          {resumeData.tech_screening_recording_download_url !==
                                          "Technical screening recording not available." ? (
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              startIcon={
                                                <GraphicEqRoundedIcon />
                                              }
                                              onClick={() => {
                                                if (
                                                  resumeData.tech_screening_recording_download_url
                                                ) {
                                                  window.open(
                                                    resumeData.tech_screening_recording_download_url,
                                                    "_blank"
                                                  );
                                                }
                                              }}
                                              sx={{
                                                borderRadius: 20,
                                                textTransform: "none",
                                                fontWeight: "bold",
                                                boxShadow:
                                                  "0 2px 5px rgba(0,0,0,0.2)",
                                                "&:hover": {
                                                  boxShadow:
                                                    "0 4px 8px rgba(0,0,0,0.3)",
                                                },
                                              }}
                                            >
                                              Download
                                            </Button>
                                          ) : (
                                            <Typography variant="body1">
                                              Not available
                                            </Typography>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )}
                                </>
                              )}
                            {index ===
                              (resumeData.screening_call_check === "0" &&
                              resumeData.technical_call_check === "1"
                                ? 2
                                : resumeData.screening_call_check === "0" &&
                                  resumeData.technical_call_check === "0"
                                ? // resumeData.technical_call_check === "0"
                                  1
                                : resumeData.technical_call_check === "0"
                                ? 2
                                : resumeData.technical_call_check === "1" &&
                                  resumeData.coding_assessment_check === "None"
                                ? 3
                                : 3) &&
                              resumeData.coding_assessment_check === "1" && (
                                <>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Coding Assessment Status
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.coding_round_status ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Coding Optimization Details
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.Optimization_Explanation ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Coding Score
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.OptimizationScore === 0
                                          ? 0
                                          : resumeData.OptimizationScore ||
                                            "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                            {index ===
                              (resumeData.screening_call_check === "0" &&
                              resumeData.technical_call_check === "1" &&
                              resumeData.coding_assessment_check === "1"
                                ? 3
                                : resumeData.screening_call_check === "0" &&
                                  resumeData.technical_call_check === "0" &&
                                  resumeData.coding_assessment_check === "None"
                                ? // resumeData.technical_call_check === "0"
                                  1
                                : resumeData.screening_call_check === "0" &&
                                  resumeData.technical_call_check === "1"
                                ? 2
                                : resumeData.screening_call_check === "0" &&
                                  resumeData.technical_call_check === "0"
                                ? 2
                                : resumeData.coding_assessment_check ===
                                    "None" &&
                                  resumeData.technical_call_check === "0"
                                ? 2
                                : resumeData.technical_call_check === "0" &&
                                  resumeData.coding_assessment_check === "1"
                                ? 3
                                : resumeData.technical_call_check === "1" &&
                                  resumeData.coding_assessment_check === "0"
                                ? 3
                                : 4) &&
                              resumeData.aptitude_assessment_check === "1" && (
                                <>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Aptitude Assessment Status
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.aptitude_round_status ||
                                          "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    sx={{
                                      "&:nth-of-type(even)": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#36454F",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Aptitude Score
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "12px 24px" }}>
                                      <Typography variant="body1">
                                        {resumeData.overall_aptitude_score
                                          ? parseFloat(
                                              resumeData.overall_aptitude_score
                                            ).toFixed(2)
                                          : "Not available"}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                ))}
            </Box>
            {/* <Box style>
            </Box> */}
          </>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: isDarkMode ? "#080808" : "#EEF3F7",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 2,

          textAlign: "center",
          height: 50,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#FFF" : "#142764" }}
        >
          &copy; copyright {new Date().getFullYear()}: Udyat Technologies
        </Typography>
      </Box>
    </Box>
  );
}
